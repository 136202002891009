import styled from 'styled-components'

export const HeadingAnchor = styled.h2({
  '::before': {
    content: '""',
    display: 'block',
    height: 200,
    marginTop: -200,
    visibility: 'hidden',
  },
})
