import { Container } from '../container'
import { SectionAnchor } from './style'

interface Props {
  id?: string
  title?: string
  features: { title: string; description: string }[]
  className?: string
}

export const FeatureList = (props: Props) => {
  const { id, title, features, className } = props

  return (
    <Container className={className}>
      {title && (
        <SectionAnchor id={id}>
          <h3 id={id} className="mb-10 text-2xl font-bold md:text-3xl md:mb-24">
            {title}
          </h3>
        </SectionAnchor>
      )}
      <SectionAnchor id={!title ? id : undefined}>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-16 gap-y-9 md:gap-y-12">
          {features.map((feature, index) => (
            <li key={index}>
              <h4 className="mb-4 text-lg font-medium text-primary">
                {feature.title}
              </h4>
              <p className="text-sm text-dark">{feature.description}</p>
            </li>
          ))}
        </ul>
      </SectionAnchor>
    </Container>
  )
}
