import { Container } from '../container'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { HeadingAnchor } from './style'

interface Props {
  id?: string
  label?: string
  title: string
  children: ReactNode
  description: string
  action?: React.ReactNode
  reverse?: boolean
}

export const FeatureSection = (props: Props) => {
  const { id, label, title, description, children, action, reverse } = props

  return (
    <Container className="py-16">
      <div
        className={clsx(
          'flex items-center justify-between flex-col-reverse',
          reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
        )}
      >
        <div
          className={clsx(
            'flex-1 lg:max-w-[464px] z-[1]',
            reverse ? 'lg:ml-[90px]' : 'lg:mr-[90px]'
          )}
        >
          {label && (
            <div className="mb-2 text-base font-medium text-primary">
              {label}
            </div>
          )}
          <HeadingAnchor
            id={id}
            className="mb-4 text-2xl font-bold md:text-3xl md:mb-6"
          >
            {title}
          </HeadingAnchor>
          <p className="text-sm">{description}</p>
          {action && <div className="mt-8">{action}</div>}
        </div>
        <div className="pb-16 lg:pb-0">{children}</div>
      </div>
    </Container>
  )
}
