import clsx from 'clsx'
import { StaticImageData } from 'next/image'

import { RetinaImage } from '../retina-image'

interface Props {
  src: StaticImageData
  alt: string
  className?: string
}

export const ProductImage = (props: Props) => {
  const { className, ...imageProps } = props
  return (
    <div
      className={clsx(
        'inline-flex rounded-md overflow-hidden relative z-10',
        className
      )}
      style={{
        boxShadow: '0px 10px 60px 0px #2F2F4B21',
      }}
    >
      <RetinaImage {...imageProps} placeholder="blur" />
    </div>
  )
}
