import { Link } from '~/components/link'
import { routes } from '~/config/routes'
import { Container } from '../container'

interface Props {}

const FEATURES = [
  {
    title: 'Online\nFundraising',
    description:
      'Mobile focused fundraising websites, peer-to-peer to recurring giving,...',
    href: routes.features.fundraising,
  },
  {
    title: 'Nonprofit\nCRM',
    description:
      'Full scale nonprofit CRM with advanced segments and unlimited contacts.',
    href: routes.features.crm,
  },
  {
    title: 'Smart\nAutomations',
    description:
      'Customizable automations that help you convert and retain new donors.',
    href: routes.features.automations,
  },
  {
    title: 'Extensive\nIntegrations',
    description:
      'Salesforce, Mailchimp, Constant Contact, Double the Donation and many more.',
    href: routes.integrations.all,
  },
]

export const MainFeatures = (props: Props) => {
  const {} = props
  return (
    <div className="bg-[#FFEAD7] pt-[128px] pb-[176px]">
      <Container>
        <div className="mb-20">
          <h2 className="text-3xl font-bold mb-2">
            Your Nonprofit Digital Headquarters
          </h2>
          <p className="text-base">
            Automations, full-fledged CRM and refined fundraising websites in
            one strong powerhouse.
          </p>
        </div>
        <ul className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {FEATURES.map(feature => (
            <li key={feature.title}>
              <Link href={feature.href}>
                <a className="block bg-white rounded group hover:drop-shadow-xl px-8 py-12 transition-all h-full">
                  <h3 className="font-bold text-2xl mb-4 group-hover:text-primary whitespace-pre">
                    {feature.title}
                  </h3>
                  <p className="text-sm mb-10">{feature.description}</p>
                  <span className="text-primary font-medium group-hover:underline">
                    Learn more
                  </span>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}
