import { Fragment, ReactNode } from 'react'
import { Button } from '~/components/button'
import { SEO } from '~/components/seo'
import { routes } from '~/config/routes'

import { Container } from '../container'

interface Props {
  title: string
  meta?: string
  description: string
  children: ReactNode
  showButtons?: boolean
}

export const Hero = (props: Props) => {
  const { title, meta, description, children, showButtons = false } = props

  return (
    <Fragment>
      <SEO title={title} description={meta ?? description} />
      <Container>
        <div className="min-h-[90vh] pt-36 pb-20 lg:pb-28 lg:flex items-center">
          <div className="flex-1 mb-6 lg:mb-0 lg:mr-12">
            <h1 className="text-3xl md:text-[3.5rem] md:leading-[4rem] font-bold mb-4 md:mb-5 whitespace-pre">
              {title}
            </h1>
            <h2 className="text-base font-semibold mb-3">{meta}</h2>
            <h2 className="text-base">{description}</h2>
            {showButtons && (
              <div className="flex mt-10 space-x-4">
                <Button
                  width="100%"
                  size="large"
                  href={routes.bookDemo}
                  external
                >
                  Contact Sales
                </Button>
              </div>
            )}
          </div>

          <div className="flex-1">{children}</div>
        </div>
      </Container>
    </Fragment>
  )
}
