import Image, { ImageProps, StaticImageData } from 'next/image'

interface Props extends ImageProps {
  src: StaticImageData
  alt: string
}

export const RetinaImage = (props: Props) => {
  return (
    <Image
      {...props}
      width={props.src.width / 2}
      height={props.src.height / 2}
      quality={100}
      alt={props.alt}
      placeholder="blur"
    />
  )
}
