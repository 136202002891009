import styled from 'styled-components'

export const SectionAnchor = styled.div({
  '&::before': {
    content: '""',
    display: 'block',
    height: 200,
    marginTop: -200,
    visibility: 'hidden',
  },
})
