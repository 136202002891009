import { Page } from 'next'
import { Button } from '~/components/button'
import { routes } from '~/config/routes'

import automationsImage from './assets/automations.jpeg'
import Blob2 from './assets/blob-2.svg'
import Blob3 from './assets/blob-3.svg'
import Blob4 from './assets/blob-4.svg'
import crmImage from './assets/crm.jpeg'
import Dots2 from './assets/dots-2.svg'
import fundraisingImage from './assets/fundraising.jpeg'
import heroImage from './assets/hero.png'
import { FeatureList } from './components/feature-list'
import { FeatureSection } from './components/feature-section'
import { Hero } from './components/hero'
import { MainFeatures } from './components/main-features'
import { ProductImage } from './components/product-image'
import { RetinaImage } from './components/retina-image'
import { HomepageLayout } from './layouts/homepage-layout'

// import { NonprofitLogos } from './components/nonprofit-logos'
// import { Testimonials } from './components/testimonials'
export const Homepage: Page = () => {
  return (
    <>
      <Hero
        title={'Raise More \nwith Less Effort'}
        meta={
          'Kindest takes the stress off fundraising so nonprofits can focus their resources on achieving their mission.'
        }
        description="Easily create beautiful fundraising campaign pages with smart automations and a Customer Relations Management (CRM) database. Book a Demo Today and kick start your Kindest fundraising page!"
        showButtons
      >
        <div className="relative">
          <div className="inline-flex lg:translate-x-8 lg:min-w-[748px]">
            <RetinaImage src={heroImage} alt="Kindest Dashboard" priority />
          </div>
        </div>
      </Hero>

      <MainFeatures />

      {/* <NonprofitLogos /> */}
      <div className="pb-20 md:pb-36">
        <FeatureSection
          title="Convert More With Fundraising Websites"
          description="Beautiful customizable websites that tell your amazing story. Mobile optimized pages are easy to use and include a free image library, peer-to-peer, recurring giving and much more to boost your fundraising efforts."
          action={
            <Button variant="outline" href={routes.features.fundraising}>
              Fundraising Websites
            </Button>
          }
        >
          <div className="relative transform xl:translate-x-[60px]">
            <ProductImage src={fundraisingImage} alt="" />
            <Blob2 className="absolute bottom-0 right-0 z-0 w-3/5 translate-x-1/4 translate-y-1/3" />
          </div>
        </FeatureSection>
        <FeatureSection
          title="Save Time With Email Automations"
          description="Kindest will automatically build better relationships with donors through personalized thank you notes and donation replies. Automations will also make sure monthly donations never fail and inspire your donors to give more."
          action={
            <Button variant="outline" href={routes.features.automations}>
              Automations
            </Button>
          }
          reverse
        >
          <div className="relative lg:-translate-x-14">
            <ProductImage src={automationsImage} alt="" />
            <Blob3 className="absolute bottom-0 left-0 z-0 w-1/2 -translate-x-1/4 translate-y-1/3" />
            {/* <Dots2 className="absolute bottom-0 left-0 w-1/6 -translate-x-1/4 translate-y-1/3" /> */}
          </div>
        </FeatureSection>
        <FeatureSection
          title="Stay In Control With Nonprofit-Centric CRM"
          description="Take care of all of your donor data in one place. Easily track donation history, build custom segments and approach each donor with the individual care that they deserve. Soft credits, supporters tagging and dynamic filters will help you stay in control."
          action={
            <Button variant="outline" href={routes.features.crm}>
              Nonprofit CRM
            </Button>
          }
        >
          <div className="relative lg:translate-x-14">
            <ProductImage src={crmImage} alt="" />
            <Blob4 className="absolute z-0 w-1/2 -top-12 -right-12" />
            <Dots2 className="absolute bottom-0 left-0 w-1/6 -translate-x-1/3 translate-y-1/3" />
          </div>
        </FeatureSection>
      </div>

      <div className="border-t">
        <FeatureList
          className="py-20 md:py-36"
          title="And Much More"
          features={[
            {
              title: 'Text-to-Donate',
              description:
                'Up to 6x times higher engagement than email, text-to-donate will bring better conversion to your next campaign.',
            },
            {
              title: 'Custom Domains',
              description:
                'Host fundraising pages and send emails straight from your own address for a greater personalized experience.',
            },
            {
              title: 'Facebook and SMS Campaigns',
              description:
                'Send your fundraising pages directly to your Facebook audience, custom segments or specific phone numbers.',
            },
            {
              title: 'Automated Stripe Payouts',
              description:
                'Accept donations from bank accounts, credit cards or Apple Pay and receive automated payouts through Stripe.',
            },
            {
              title: 'Technical Support',
              description:
                'We help you with domain setup, integrations, payouts and other technical challenges.',
            },
            {
              title: 'Fundraising Consultant',
              description:
                'A fundraising consultant helps ensure that you meet and exceed your fundraising goals.',
            },
          ]}
        />
      </div>

      {/* <Testimonials /> */}
    </>
  )
}

Homepage.getLayout = HomepageLayout
